import { Button, TextField } from "@mui/material";
import useAuth from "./useAuth";
import { useState } from "react";
import { createTeam } from "./Api.js";

const CreateTeam = () => {
  const { auth } = useAuth();

  const [name, setName] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    createTeam(auth.accessToken, { name })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        id="name-input"
        label="Team Name"
        onChange={(e) => setName(e.target.value)}
      ></TextField>
      <Button type="submit">Create Team</Button>
    </form>
  );
};

export default CreateTeam;
