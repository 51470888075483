import { createContext, useState } from "react";

const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const accessToken = localStorage.getItem("accessToken");
  const authenticated = Boolean(accessToken);
  const [auth, setAuth] = useState({ accessToken, authenticated });

  const signin = (accessToken) => {
    localStorage.setItem("accessToken", accessToken);
    setAuth({ authenticated: true, accessToken });
  };

  const logout = () => {
    localStorage.removeItem("accessToken");
    setAuth({ authenticated: false, accessToken: null });
  };

  return (
    <AuthContext.Provider value={{ auth, signin, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
