import { useEffect, useState } from "react";
import { createSquad, getPlayers } from "./Api";
import useAuth from "./useAuth";
import PlayerTable from "./PlayerTable";
import SelectedPlayerTable from "./SelectedPlayerTable";
import { Button } from "@mui/material";
import { SQUAD_BUDGET } from "./Constants";

const CreateSquad = () => {
  const { auth } = useAuth();
  const [players, setPlayers] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState([]);

  const addPlayer = (id) => {
    if (!selectedPlayers?.some((player) => player.id === id)) {
      setSelectedPlayers([
        ...selectedPlayers,
        players.find((player) => player.id === id),
      ]);
    }
  };

  const removePlayer = (id) => {
    if (selectedPlayers.some((player) => player.id === id)) {
      setSelectedPlayers(selectedPlayers.filter((player) => player.id !== id));
    }
  };

  useEffect(() => {
    getPlayers(auth.accessToken).then((response) => {
      if (response.ok) {
        return response.json().then((json) => setPlayers(json));
      }
    });
  }, [auth.accessToken]);

  const remainingBudget =
    SQUAD_BUDGET -
    selectedPlayers.reduce(
      (accumulator, currentValue) => accumulator + currentValue.value,
      0
    );

  const handleCreateSquad = () => {
    createSquad(auth.accessToken, {
      players: selectedPlayers.map((player) => player.id),
    }).then();
  };

  return (
    <>
      <p>Budget: {remainingBudget}</p>
      <SelectedPlayerTable
        selectedPlayers={selectedPlayers}
        removePlayer={removePlayer}
      />
      <PlayerTable players={players} addPlayer={addPlayer} />
      <Button
        onClick={handleCreateSquad}
        disabled={selectedPlayers.length !== 5 || remainingBudget < 0}
      >
        Create Squad
      </Button>
    </>
  );
};

export default CreateSquad;
