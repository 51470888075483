import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import SelectedPlayerRow from "./SelectedPlayerRow";

const SelectedPlayerTable = (props) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Value</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.selectedPlayers.map((player) => {
          return (
            <SelectedPlayerRow
              removePlayer={props.removePlayer}
              key={player.id}
              id={player.id}
              name={player.name}
              value={player.value}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};

export default SelectedPlayerTable;
