import { useState } from "react";
import { TextField, Button } from "@mui/material";
import { confirmSignup } from "./Api";
import { useLocation, useNavigate } from "react-router-dom";

const ConfirmSignup = () => {
  const location = useLocation();
  const [email, setEmail] = useState(location.state?.email);
  const [code, setCode] = useState();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    confirmSignup({ email, code })
      .then((response) => {
        if (response.ok) {
          navigate("/signin");
        }
      })
      .catch((error) => console.log(error));
  };

  const handleExpiredCodeClick = () => {
    navigate("/resendCode");
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        id="email-input"
        label="Email Address"
        onChange={(e) => setEmail(e.target.value)}
        value={email || ""}
      ></TextField>
      <TextField
        id="code-input"
        label="Verification Code"
        onChange={(e) => setCode(e.target.value)}
      ></TextField>
      <Button type="submit">Confirm Sign Up</Button>
      <Button onClick={handleExpiredCodeClick}>Code Expired?</Button>
    </form>
  );
};

export default ConfirmSignup;
