const baseUrl = process.env.REACT_APP_BASE_URL;

export async function signin(signinRequest) {
  return await fetch(baseUrl + "/user/signin", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(signinRequest),
  });
}

export async function signup(signupRequest) {
  return await fetch(baseUrl + "/user/signup", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(signupRequest),
  });
}

export async function confirmSignup(confirmSignupRequest) {
  return await fetch(baseUrl + "/user/confirmSignup", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(confirmSignupRequest),
  });
}

export async function resendCode(resendCodeRequest) {
  return await fetch(baseUrl + "/user/resendCode", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(resendCodeRequest),
  });
}

export async function forgotPassword(forgotPasswordRequest) {
  return await fetch(baseUrl + "/user/forgotPassword", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(forgotPasswordRequest),
  });
}

export async function changePassword(changePasswordRequest) {
  return await fetch(baseUrl + "/user/changePassword", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(changePasswordRequest),
  });
}

export async function createTeam(accessToken, createTeamRequest) {
  return await fetch(baseUrl + "/team", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
    body: JSON.stringify(createTeamRequest),
  });
}

export async function getPlayers(accessToken) {
  return await fetch(baseUrl + "/players", {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  });
}

export async function createSquad(accessToken, createSquadRequest) {
  return await fetch(baseUrl + "/squad", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
    body: JSON.stringify(createSquadRequest),
  });
}
