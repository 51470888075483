import AppRoutes from "./AppRoutes";
import Navigation from "./Navigation";

const App = () => {
  return (
    <Navigation>
      <AppRoutes />
    </Navigation>
  );
};

export default App;
