import { Button, TableCell, TableRow } from "@mui/material";

const PlayerRow = (props) => {
  const addPlayer = () => {
    props.addPlayer(props.id);
  };

  return (
    <TableRow>
      <TableCell>{props.name}</TableCell>
      <TableCell>{props.value}</TableCell>
      <TableCell>
        <Button onClick={addPlayer}>Add</Button>
      </TableCell>
    </TableRow>
  );
};

export default PlayerRow;
