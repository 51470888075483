import { useState } from "react";
import { forgotPassword } from "./Api";
import { useNavigate } from "react-router-dom";
import { Button, TextField } from "@mui/material";

const ForgotPassword = () => {
  const [email, setEmail] = useState();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    forgotPassword({ email })
      .then((response) => {
        if (response.ok) {
          navigate("/changePassword", { state: { email } });
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        id="email-input"
        label="Email Address"
        onChange={(e) => setEmail(e.target.value)}
      ></TextField>
      <Button type="submit">Send Password Reset Code</Button>
    </form>
  );
};

export default ForgotPassword;
