import { Button, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "./useAuth";

const Navigation = (props) => {
  const navigate = useNavigate();
  const { auth, logout } = useAuth();

  const [menu, setMenu] = useState(null);

  let open = Boolean(menu);

  const handleClick = (e) => {
    setMenu(e.currentTarget);
  };

  const handleClose = () => {
    setMenu(null);
  };

  const handleLogout = () => {
    logout();
  };

  const handleSignin = () => {
    navigate("/signin");
    handleClose();
  };

  const handleSquadClick = () => {};

  const handleRulesClick = () => {};

  return (
    <>
      <Button id="navigation-button" onClick={handleClick}>
        Menu
      </Button>
      <Menu
        id="logged-in-menu"
        anchorEl={menu}
        open={open}
        onClose={handleClose}
      >
        {!auth.authenticated && (
          <MenuItem onClick={handleSignin}>Signin</MenuItem>
        )}
        {auth.authenticated && (
          <MenuItem onClick={handleSquadClick}>Squad</MenuItem>
        )}
        {auth.authenticated && (
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        )}
        <MenuItem onClick={handleRulesClick}>Rules</MenuItem>
      </Menu>
      {props.children}
    </>
  );
};

export default Navigation;
