import { useState } from "react";
import { changePassword } from "./Api";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, TextField } from "@mui/material";

const ChangePassword = () => {
  const location = useLocation();
  const [email, setEmail] = useState(location.state?.email);
  const [forgotPasswordCode, setForgotPasswordCode] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    changePassword({ email, forgotPasswordCode, password })
      .then((response) => {
        if (response.ok) {
          navigate("/signin", { state: { email } });
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        id="email-input"
        label="Email Address"
        onChange={(e) => setEmail(e.target.value)}
        value={email || ""}
      ></TextField>
      <TextField
        id="password-input"
        label="Password"
        onChange={(e) => setPassword(e.target.value)}
      ></TextField>
      <TextField
        id="code-input"
        label="Verification Code"
        onChange={(e) => setForgotPasswordCode(e.target.value)}
      ></TextField>
      <Button type="submit">Change Password</Button>
    </form>
  );
};

export default ChangePassword;
