import { TextField, Button } from "@mui/material";
import { useState } from "react";
import { signup } from "./Api";
import { useNavigate } from "react-router-dom";

const Signup = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    signup({ email, password, firstName, lastName })
      .then((response) => {
        if (response.ok) {
          navigate("/confirmSignup", { state: { email } });
        }
      })
      .catch((error) => console.log(error));
  };

  const handleGotACodeClick = () => {
    navigate("/confirmSignup");
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        id="email-input"
        label="Email Address"
        onChange={(e) => setEmail(e.target.value)}
      ></TextField>
      <TextField
        id="password-input"
        label="Password"
        type="password"
        onChange={(e) => setPassword(e.target.value)}
      ></TextField>
      <TextField
        id="firstName-input"
        label="First Name"
        onChange={(e) => setFirstName(e.target.value)}
      ></TextField>
      <TextField
        id="lastName-input"
        label="Last Name"
        onChange={(e) => setLastName(e.target.value)}
      ></TextField>
      <Button type="submit">Sign Up</Button>
      <Button onClick={handleGotACodeClick}>Got a Code?</Button>
    </form>
  );
};

export default Signup;
