import { Button, TableCell, TableRow } from "@mui/material";

const SelectedPlayerRow = (props) => {
  const removePlayer = () => {
    props.removePlayer(props.id);
  };

  return (
    <TableRow>
      <TableCell>{props.name}</TableCell>
      <TableCell>{props.value}</TableCell>
      <TableCell>
        <Button onClick={removePlayer}>Remove</Button>
      </TableCell>
    </TableRow>
  );
};

export default SelectedPlayerRow;
