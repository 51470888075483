import { Button, TextField } from "@mui/material";
import useAuth from "./useAuth.js";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { signin } from "./Api.js";

function Signin() {
  const location = useLocation();
  const [email, setEmail] = useState(location.state?.email);
  const [password, setPassword] = useState("");

  const auth = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    signin({ email, password })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((responseJson) => {
        const accessToken = responseJson.accessToken;
        auth.signin(accessToken);
      })
      .catch((error) => console.log(error));
  };

  const handleSignUpClick = () => {
    navigate("/signup");
  };

  const handleForgotPasswordClick = () => {
    navigate("/forgotPassword");
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <TextField
          id="email-input"
          label="Email Address"
          onChange={(e) => setEmail(e.target.value)}
          value={email || ""}
        ></TextField>
        <TextField
          id="password-input"
          label="Password"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        ></TextField>
        <Button type="submit">Sign In</Button>
        <Button onClick={handleForgotPasswordClick}>Forgot Password?</Button>
        <Button onClick={handleSignUpClick}>Sign Up</Button>
      </form>
    </>
  );
}

export default Signin;
