import { useState } from "react";
import { resendCode } from "./Api";
import { useNavigate } from "react-router-dom";
import { Button, TextField } from "@mui/material";

const ResendCode = () => {
  const [email, setEmail] = useState();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    resendCode({ email })
      .then((response) => {
        if (response.ok) {
          navigate("/confirmSignup", { state: { email } });
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        id="email-input"
        label="Email Address"
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button type="submit">Resend Verification Code</Button>
    </form>
  );
};

export default ResendCode;
