import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Login from "./Signin";
import Signup from "./Signup";
import ConfirmSignup from "./ConfirmSignup";
import CreateTeam from "./CreateTeam";
import CreateSquad from "./CreateSquad";
import ResendCode from "./ResendCode";
import ForgotPassword from "./ForgotPassword";
import ChangePassword from "./ChangePassword";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/signin" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/confirmSignup" element={<ConfirmSignup />} />
      <Route path="/resendCode" element={<ResendCode />} />
      <Route path="/forgotPassword" element={<ForgotPassword />} />
      <Route path="/changePassword" element={<ChangePassword />} />
      <Route element={<ProtectedRoute />}>
        <Route path="/createTeam" element={<CreateTeam />} />
        <Route path="/createSquad" element={<CreateSquad />} />
      </Route>
    </Routes>
  );
}

export default AppRoutes;
